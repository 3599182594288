import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import Header from './Header';
import { EntityGridProps, EntityGridRef } from './types';
import Box from '@material-ui/core/Box';
import { DataTable } from 'ui-kit';
import dataTableReducer, { dataTableInitialState } from './state';
import StateContext from './state/StateContext';
import { GridConfig } from './constants';
import {
  GridFilterItem,
  GridFilterModel,
  GridSortItem,
  GridSortModel
} from '@material-ui/data-grid';
import {
  getTableData,
  triggerColumnFilter,
  triggerPagination,
  triggerSort
} from './state/actionCreator';
import { fetchData } from './state/service';
import useStyles from './styles';

const EntityGrid = forwardRef<EntityGridRef, EntityGridProps>(
  (
    {
      actionContent,
      entityName,
      rows,
      columns,
      searchableColumns = [],
      extraFilters = [],
      placeholder = 'Search...',
      isGqlAPI = false,
      graphqlService,
      loading = false,
      isServerHandled = true,
      setResponse,
      ...rest
    },
    ref
  ) => {
    const [state, dispatch] = useReducer(dataTableReducer, dataTableInitialState);
    const classes = useStyles();
    const isInit = useRef(true);

    useEffect(() => {
      if (isInit.current || isServerHandled) {
        fetchRows(
          state.activePage,
          state.filtrationConfig,
          state.sortConfig,
          state.columnFiltrationConfig
        );
        isInit.current = false;
      }
    }, [
      state.filtrationConfig,
      state.sortConfig,
      state.activePage,
      state.columnFiltrationConfig,
      isServerHandled,
      isInit.current
    ]);

    const refresh = () => {
      fetchRows(
        state.activePage,
        state.filtrationConfig,
        state.sortConfig,
        state.columnFiltrationConfig
      );
    };

    useImperativeHandle<EntityGridRef, EntityGridRef>(ref, () => ({
      refresh
    }));

    const fetchRows = async (
      newPage: number,
      filterConfig: GridFilterItem | null,
      sortConfig: GridSortItem | null,
      columnFiltrationConfig: GridFilterItem | null
    ) => {
      dispatch(getTableData());
      const response = await fetchData(
        dispatch,
        entityName,
        newPage,
        filterConfig,
        sortConfig,
        searchableColumns,
        extraFilters,
        isGqlAPI,
        columnFiltrationConfig,
        graphqlService
      );
      if (response && !!setResponse) {
        setResponse(response);
      }
    };

    const onPageChange = (newPage: number) => {
      dispatch(triggerPagination(newPage));
    };

    const onFilterChange = (model: GridFilterModel) => {
      dispatch(triggerColumnFilter(model.items[0]));
    };

    const onSortChange = (model: GridSortModel) => {
      if (model?.length === 0) {
        return;
      }
      dispatch(triggerSort(model[0]));
    };

    return (
      <StateContext.Provider value={{ state, dispatch }}>
        <Box className={classes.root}>
          <Header
            actionContent={actionContent}
            entityName={entityName}
            entitySubTitle={`More than ${state.totalRows} rows available`}
            placeholder={placeholder}
            showRefreshIcon={true}
            onRefresh={refresh}
          />
          <DataTable
            columns={columns}
            rows={state.rows}
            rowCount={state.totalRows}
            rowsPerPageOptions={GridConfig.rowsPerPageOptions}
            pageSize={GridConfig.pageSize}
            page={state.activePage}
            loading={state.isLoading || loading}
            pagination
            onPageChange={onPageChange}
            paginationMode={isServerHandled ? 'server' : 'client'}
            filterMode={isServerHandled ? 'server' : 'client'}
            onFilterModelChange={onFilterChange}
            sortingMode={isServerHandled ? 'server' : 'client'}
            onSortModelChange={onSortChange}
            {...rest}
          />
        </Box>
      </StateContext.Provider>
    );
  }
);

export default EntityGrid;
