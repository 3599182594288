import { Dispatch } from 'react';
import { ActionType } from './actionTypes';
import { Axios, generateCancelToken, cancelRequest, CancelTokenSource } from 'core';
import { getTableDataError, getTableDataSuccess } from './actionCreator';
import { GetTableDataResponse } from '../types';
import { GridFilterItem, GridRowData, GridSortItem } from '@material-ui/data-grid';
import { buildFilterQueryParam } from '../utils';
import { CANCELED_ERROR } from '../constants';

let prevRequest: CancelTokenSource;

export const fetchData = async (
  dispatch: Dispatch<ActionType>,
  entityName: string,
  activePage: number,
  filterConfig: GridFilterItem | null,
  sortConfig: GridSortItem | null,
  searchableColumns: string[],
  extraFilters: { [key: string]: string }[],
  isGqlAPI: boolean,
  columnFiltrationConfig: GridFilterItem | null,
  graphqlService?: (
    activePage: number,
    pageSize: number,
    filters: { [p: string]: string }
  ) => Promise<{ totalCount: number; entities: any }>
) => {
  try {
    let entities: GridRowData[] = [],
      totalCount = 0;

    if (isGqlAPI && graphqlService) {
      const filterValue = filterConfig?.value as string;
      const filters: { [p: string]: string } = {};
      searchableColumns.forEach((col) => {
        filters[col] = filterValue?.trim().toLowerCase();
      });
      if (columnFiltrationConfig && columnFiltrationConfig.columnField) {
        filters[columnFiltrationConfig.columnField] = columnFiltrationConfig?.value;
      }

      const response = await graphqlService(activePage, 10, filters);
      entities = response.entities;
      totalCount = response.totalCount;
    } else {
      const response = await fetchRestAPIData(
        entityName,
        activePage,
        filterConfig,
        sortConfig,
        searchableColumns,
        extraFilters
      );
      entities = response.data.entities;
      totalCount = response.data.totalCount;
    }

    dispatch(
      getTableDataSuccess({
        entities,
        totalCount
      })
    );
    return { entities, totalCount };
  } catch (e) {
    if (e !== CANCELED_ERROR) {
      dispatch(getTableDataError(e));
    }
  }
};

const fetchRestAPIData = async (
  entityName: string,
  activePage: number,
  filterConfig: GridFilterItem | null,
  sortConfig: GridSortItem | null,
  searchableColumns: string[],
  extraFilters: { [key: string]: string }[]
) => {
  // TODO FE-12
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  cancelRequest(prevRequest);

  let queryParam = `?page=${activePage + 1}&page_size=${10}`;

  if (filterConfig || extraFilters) {
    const filters = buildFilterQueryParam(filterConfig, searchableColumns, extraFilters);
    queryParam += `&filters=${filters}`;
  }

  if (sortConfig) {
    queryParam += `&order=${sortConfig.sort === 'desc' ? '-' : ''}${sortConfig.field}`;
  }

  // TODO FE-12
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
  prevRequest = generateCancelToken();

  return await Axios.get<GetTableDataResponse>(`/golden_gate/${entityName}${queryParam}`, {
    // TODO FE-12
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
    cancelToken: prevRequest?.token
  });
};
