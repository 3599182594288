import { Axios } from 'core';
import React, { useState } from 'react';
import { EntityGrid, notify } from 'store';

import { getPrivateDocumentIngestionColDef } from './constants';
import { errorMsgResolver } from '../Documents/utils';
import { reRunDocumentIngest } from './services';

const PrivateDocumentIngestion = () => {
  const [isLoading, setIsLoading] = useState(false);

  const reIngestDocument = async (id: number): Promise<void> => {
    try {
      setIsLoading(true);
      await reRunDocumentIngest(id);
      notify({
        message: 'Successfully ReIngested document',
        severity: 'success',
        open: true
      });
      setIsLoading(false);
    } catch (error) {
      notify({
        message: errorMsgResolver(error as string),
        severity: 'error',
        open: true
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <EntityGrid
        actionContent={<React.Fragment />}
        entityName="private-document-ingestion"
        rows={[]}
        columns={getPrivateDocumentIngestionColDef(reIngestDocument)}
        loading={isLoading}
        searchableColumns={['id']}
        placeholder={'Search By Id'}
      />
    </>
  );
};

export default PrivateDocumentIngestion;
