import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { AuthenticatedRoute } from 'core';
import { Header } from 'store';
import { CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Footer, Sidebar } from 'ui-kit';
import Box from '@material-ui/core/Box';
import Companies from 'components/Companies';
import CompSheets from 'components/CompSheets';
import Documents from 'components/Documents';
import LogsTable from 'components/BatchJobsLogs';
import { selectIsLoading } from 'components/Home/state/selectors';
import { useAction } from 'components/Home/hooks/useAction';

import useStyles from './style';
import { HomeRoutes } from './routes';
import { Sidebar_Items } from './constants';
import { logout } from 'core/modules/auth.utils';
import { AuthorizedRoute } from 'store';
import useAuthorization from '../../hooks/useAuthorization';
import { PermissionKeys } from 'config/permission-keys';
import ApiKeys from '../APIKeys';
import Users from '../Users';
import { SnapshotModels } from '../SnapshotModels';
import IndustryModels from '../IndustryModels';
import Configurations from '../Configurations';
import PreqinIngestion from '../PreqinIngestion';
import TranscriptIngest from '../Documents/TranscriptIngest';
import PrivateDocumentIngestion from '../PrivateDocumentIngestion';

const Home: React.FC = () => {
  const classes = useStyles();
  const isLoading = useSelector(selectIsLoading);
  const { getUser } = useAction();
  const canViewCompanies = useAuthorization(PermissionKeys.CAN_VIEW_COMPANIES);
  const canViewDocuments = useAuthorization(PermissionKeys.CAN_VIEW_DOCUMENT);
  const canViewPrivateIngestion = useAuthorization(PermissionKeys.CAN_VIEW_PRIVATE_INGESTION);
  const canViewCrawlerJobs = useAuthorization(PermissionKeys.CAN_VIEW_CRAWLER_JOBS);
  const canAddAPIKey = useAuthorization(PermissionKeys.CAN_ADD_API_KEY);
  const canViewConfigurations = useAuthorization(PermissionKeys.CAN_VIEW_CONFIGURATIONS);

  const onLogout = () => {
    logout();
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title="Goldengate" onLogout={onLogout} />
      <main className={classes.main}>
        {isLoading ? (
          <Box pt="30px" display="flex" alignItems="center" justifyContent="center" width="100%">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box width="350px">
              <Sidebar items={Sidebar_Items} />
            </Box>
            <Box className={classes.container}>
              <Switch>
                <AuthenticatedRoute path={HomeRoutes.Companies}>
                  <AuthorizedRoute isAuthorized={canViewCompanies}>
                    <Companies />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                {/*<AuthenticatedRoute path={HomeRoutes.CompSheets}>*/}
                {/*  <CompSheets />*/}
                {/*</AuthenticatedRoute>*/}
                <AuthenticatedRoute path={HomeRoutes.ApiKeys}>
                  <AuthorizedRoute isAuthorized={canAddAPIKey}>
                    <ApiKeys />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                <AuthenticatedRoute path={HomeRoutes.Documents}>
                  <AuthorizedRoute isAuthorized={canViewDocuments}>
                    <Documents />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                <AuthenticatedRoute path={HomeRoutes.BatchLogs}>
                  <AuthorizedRoute isAuthorized={canViewCrawlerJobs}>
                    <LogsTable />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                <AuthenticatedRoute path={HomeRoutes.PrivateDocumentIngestion}>
                  <AuthorizedRoute isAuthorized={canViewCrawlerJobs}>
                    <PrivateDocumentIngestion />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                <AuthenticatedRoute path={HomeRoutes.Users}>
                  <AuthorizedRoute isAuthorized={canAddAPIKey}>
                    <Users />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                <AuthenticatedRoute path={HomeRoutes.SnapshotModels}>
                  <AuthorizedRoute isAuthorized={canViewCompanies}>
                    <SnapshotModels />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                <AuthenticatedRoute path={HomeRoutes.IndustryModels}>
                  <AuthorizedRoute isAuthorized={canViewCompanies}>
                    <IndustryModels />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                <AuthenticatedRoute path={HomeRoutes.Configurations}>
                  <AuthorizedRoute isAuthorized={canViewConfigurations}>
                    <Configurations />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                <AuthenticatedRoute path={HomeRoutes.Preqin}>
                  <AuthorizedRoute isAuthorized={canViewPrivateIngestion}>
                    <PreqinIngestion />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                <AuthenticatedRoute path={HomeRoutes.Transcripts}>
                  <AuthorizedRoute isAuthorized={canViewDocuments}>
                    <TranscriptIngest />
                  </AuthorizedRoute>
                </AuthenticatedRoute>
                <Route
                  exact={true}
                  path="/home"
                  render={() => <Redirect to={HomeRoutes.Documents} />}
                />
                <Route path="/*" render={() => <Redirect to={HomeRoutes.Documents} />} />
              </Switch>
            </Box>
          </>
        )}
      </main>
      <Footer />
    </>
  );
};

export default Home;
