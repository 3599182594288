import { GridFilterItem } from '@material-ui/data-grid';
import { TableOperatorsMap } from './constants';

export const buildFilterQueryParam = (
  filterConfig: GridFilterItem | null,
  searchableColumns: string[],
  extraFilters: { [key: string]: string }[]
) => {
  let filterStr = '';
  if (filterConfig?.value && searchableColumns?.length > 0) {
    const filterValue = filterConfig?.value as string;
    filterStr = searchableColumns
      .map((col) => `"${col}__icontains":"${filterValue?.trim()}"`)
      .join(',');
  }

  const extraFiltersString = extraFilters
    .map((filter) => {
      return Object.keys(filter).map((filterKey: string, index) => {
        if (extraFilters?.[index]?.[filterKey]) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          return `"${filterKey}":"${extraFilters?.[index][filterKey]}"`;
        }
        return undefined;
      });
    })
    .filter(Boolean)
    .join(',');

  const filters = [filterStr, extraFiltersString].filter(Boolean).join(',');
  return `{${filters}}`;
};

/**
 * Map the operator coming from the table to the operator Backend can understand
 * Ex:
 * table filter operator is Equals
 * Backend should receive Exact
 */
export const mapOperator = (filterOperator: string) => TableOperatorsMap[filterOperator];
